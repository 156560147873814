import { Component } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.css'
})
export class TermsComponent {
  navClass = "nav-dark";  
  // Set Topbar Option
  Menuoption = 'center';
  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 1,
    decimalPlaces: 0,
  };
  // Set Topbar Option
  constructor() { }

}
