import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.css'
})
export class PrivacyComponent {
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 1,
    decimalPlaces: 0,
  };

  navClass = "nav-dark";  

  constructor() { }
}
