
<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/services/sw01.jpg') center; background-size: cover;" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h2 class="text-white title-dark"> Nos Services </h2>
                </div>
            </div><!--end col-->
        </div><!--end row-->
        
        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/">CLIMAPLOM</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Services</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="row align-items-center">
                    <div class="col-12 col-md-4 col-lg-4 col-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div (click)="scroll(plomberie)" 
                        class="card work-container work-primary work-modern overflow-hidden rounded border-0 shadow-md cursor-pointer">
                        <div class="card-body p-0">
                            <img src="assets/images/services/s01.jpg" class="img-fluid" alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content opacity-100 ">
                                <a href="javascript:void(0)" class="title text-white d-block fw-bold pe-none">Plomberie</a>
                                <a href="javascript:void(0)" class="title text-white d-block pe-none">Lire plus ...</a>
                            </div>
                            
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-12 col-md-4 col-lg-4 col-4">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                            <div (click)="scroll(chauffage)"
                            class="card work-container work-primary work-modern overflow-hidden rounded border-0 shadow-md cursor-pointer">
                            <div class="card-body p-0">
                                <img src="assets/images/services/s03.jpeg" class="img-fluid"
                                alt="work-image">
                                <div class="overlay-work"></div>
                                <div class="content opacity-100">
                                    <a href="javascript:void(0)"
                                    class="title text-white d-block fw-bold pe-none">Chauffage</a>
                                    <a href="javascript:void(0)" class="title text-white d-block pe-none">Lire plus ...</a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-12 col-lg-12 col-md-12 mt-4 pt-2">
                        <div (click)="scroll(ventilation)"
                        class="card work-container work-primary work-modern overflow-hidden rounded border-0 shadow-md cursor-pointer">
                        <div class="card-body p-0">
                            <img src="assets/images/services/s05.jpeg" class="img-fluid"
                            alt="work-image">
                            <div class="overlay-work"></div>
                            <div class="content opacity-100">
                                <a href="javascript:void(0)"
                                class="title text-white d-block fw-bold pe-none">Ventilation</a>
                                <a href="javascript:void(0)" class="title text-white d-block pe-none">Lire plus ...</a>
                            </div>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end col-->
        
        <div class="col-12 col-md-4 col-lg-4 col-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div (click)="scroll(climatisation)"
            class="card work-container work-primary work-modern overflow-hidden rounded border-0 shadow-md cursor-pointer">
            <div class="card-body p-0">
                <img src="assets/images/services/s04.jpeg" class="img-fluid" alt="work-image">
                <div class="overlay-work"></div>
                <div class="content opacity-100">
                    <a href="javascript:void(0)" class="title text-white d-block fw-bold pe-none">Climatisation</a>
                    <a href="javascript:void(0)" class="title text-white d-block pe-none">Lire plus ...</a>
                </div>
            </div>
        </div>
    </div>
</div><!--end row-->
</div><!--end col-->

<div class="col-lg-5 col-md-5 mt-4 mt-lg-0 pt- pt-lg-0">
    <div class="ms-lg-4">
        <div class="section-title">
            <span class="badge bg-soft-primary rounded-pill fw-bold">Notre Engagement</span>
            <p class="text-muted para-desc mb-4 mt-3 text-justify">Bienvenue chez <span
                class="text-primary fw-bold">CLIMAPLOM</span> , votre partenaire de confiance pour tous vos besoins en plomberie, que vous soyez un particulier à la recherche de solutions domestiques ou une grande entreprise nécessitant des services commerciaux de qualité. Nous sommes fiers de fournir des services professionnels et fiables à tous nos clients, avec une attention particulière à leurs besoins spécifiques.</p>
            </div>
        </div>
    </div>
</div><!--end row-->
</div><!--end container-->
</section><!--end section-->

<section class="section bg-light" #plomberie>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="section-title text-center">
                    <h4 class="title mt-4 mb-0 text-primary">Plomberie</h4>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-10 mt-4 pt-2">
                <div class="row">
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Installation et réparation de systèmes de plomberie</h5>
                        <p class="text-muted text-justify">Que vous ayez besoin d'installer de nouvelles infrastructures de plomberie pour un nouveau bâtiment ou de réparer des systèmes existants, notre équipe expérimentée est là pour vous fournir des solutions efficaces et durables.</p>
                    </div>
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Gestion des installations complexes</h5>
                        <p class="text-muted text-justify">Pour les grandes entreprises ayant des besoins complexes en plomberie, nous proposons des services de gestion de projet complets pour assurer une installation et une maintenance sans faille de vos systèmes de plomberie.</p>
                    </div>
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Maintenance préventive</h5>
                        <p class="text-muted text-justify">Nous proposons des programmes de maintenance préventive personnalisés pour garantir le bon fonctionnement continu de vos systèmes de plomberie, minimisant ainsi les temps d'arrêt et les coûts de réparation imprévus.</p>    
                    </div>
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Services résidentiels complets</h5>
                        <p class="text-muted text-justify">Pour les particuliers et copropriétés, nous offrons une gamme complète de services de plomberie, allant de la réparation des fuites et du débouchage des canalisations à l'installation de nouveaux appareils sanitaires et de systèmes de chauffage d'eau.</p>    
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<section class="section" #chauffage>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="section-title text-center">
                    <h4 class="title mt-4 mb-0 text-primary">Chauffage</h4>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-10 mt-4 pt-2">
                <div class="row">
                    <div class="col-lg-4">
                        <h5 class="title text-primary bloc-title-50">Installation et entretien de systèmes de chauffage</h5>
                        <p class="text-muted text-justify">Nous proposons l'installation complète de divers systèmes de chauffage, y compris les chaudières, les systèmes de chauffage central, les pompes à chaleur, les radiateurs et bien plus encore. Notre équipe assure également l'entretien régulier de ces systèmes pour garantir leur bon fonctionnement et leur efficacité énergétique optimale.</p>
                    </div>
                    <div class="col-lg-4">
                        <h5 class="title text-primary bloc-title-50">Réparation et dépannage</h5>
                        <p class="text-muted text-justify">En cas de panne ou de dysfonctionnement de votre système de chauffage, notre équipe est disponible pour diagnostiquer et réparer rapidement tout problème, minimisant ainsi les temps d'arrêt et les perturbations dans votre quotidien ou vos activités commerciales.</p>
                    </div>
                    <div class="col-lg-4">
                        <h5 class="title text-primary bloc-title-50">Solutions sur mesure</h5>
                        <p class="text-muted text-justify">Nous comprenons que chaque projet de chauffage est unique. C'est pourquoi nous travaillons en étroite collaboration avec nos clients pour concevoir des solutions sur mesure répondant à leurs besoins spécifiques en matière de chauffage, qu'il s'agisse de nouveaux projets de construction ou de rénovations.</p>    
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<section class="section bg-light" #ventilation>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="section-title text-center">
                    <h4 class="title mt-4 mb-0 text-primary">Ventilation</h4>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-10 mt-4 pt-2">
                <div class="row">
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Installation de systèmes de ventilation</h5>
                        <p class="text-muted text-justify">Nous concevons et installons des systèmes de ventilation sur mesure pour répondre aux besoins spécifiques de chaque client. Que ce soit pour une maison individuelle, un immeuble résidentiel ou un bâtiment commercial, nous avons l'expertise nécessaire pour créer des solutions de ventilation efficaces et économes en énergie.</p>
                    </div>
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Entretien et nettoyage des systèmes de ventilation</h5>
                        <p class="text-muted text-justify">Pour garantir le bon fonctionnement et la durabilité de vos systèmes de ventilation, nous proposons des services d'entretien régulier et de nettoyage professionnel. Cela comprend le remplacement des filtres, le nettoyage des conduits d'air et des ventilateurs, ainsi que la vérification des composants électriques.</p>
                    </div>
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Ventilation mécanique contrôlée (VMC)</h5>
                        <p class="text-muted text-justify">Nous sommes spécialisés dans l'installation et l'entretien de systèmes de VMC, qui permettent de renouveler l'air intérieur en évacuant l'air vicié et en introduisant de l'air frais de manière contrôlée. Les systèmes de VMC contribuent à améliorer la qualité de l'air intérieur et à prévenir les problèmes de santé liés à une mauvaise ventilation.</p>    
                    </div>
                    <div class="col-lg-3">
                        <h5 class="title text-primary bloc-title-100">Solutions de ventilation commerciale</h5>
                        <p class="text-muted text-justify">Pour les entreprises, nous proposons des solutions de ventilation commerciale adaptées à différents types de locaux, notamment les restaurants, les commerces de détail, les bureaux et les installations industrielles. Nous pouvons concevoir des systèmes de ventilation sur mesure pour répondre aux exigences de confort des employés et des clients, ainsi qu'aux normes de sécurité en vigueur.
                        </p>    
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<section class="section" #climatisation>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="section-title text-center">
                    <h4 class="title mt-4 mb-0 text-primary">Climatisation</h4>
                </div>
            </div><!--end col-->
            <p class="text-muted mt-4 text-justify">
                L'installation de systèmes de climatisation est une étape cruciale pour garantir le confort thermique et la qualité de l'air intérieur dans les environnements résidentiels, commerciaux et industriels. Chez <span
                class="text-primary fw-bold">CLIMAPLOM</span>, nous nous engageons à fournir des services d'installation professionnels et personnalisés pour répondre aux besoins spécifiques de chaque client.
            </p>
            <div class="col-lg-10 mt-4 pt-2">
                <div class="row">
                    <div class="col-lg-4">
                        <h5 class="title text-primary bloc-title-50">Évaluation des besoins</h5>
                        <p class="text-muted text-justify">Avant de procéder à l'installation d'un système de climatisation, notre équipe effectue une évaluation approfondie des besoins du client. Nous prenons en compte des facteurs tels que la taille de l'espace à climatiser, l'isolation du bâtiment, les exigences de confort thermique et les préférences du client en matière de contrôle de la température.</p>
                    </div>
                    <div class="col-lg-4">
                        <h5 class="title text-primary bloc-title-50">Recommandations personnalisées</h5>
                        <p class="text-muted text-justify">Sur la base de l'évaluation des besoins, nous recommandons les meilleurs systèmes de climatisation adaptés à chaque situation. Cela peut inclure des climatiseurs split traditionnels, des systèmes de climatisation centrale pour les grandes surfaces, des systèmes à cycle inversé pour un chauffage et une climatisation efficaces toute l'année, ou des climatiseurs sans conduit pour une installation discrète et flexible.</p>
                    </div>
                    <div class="col-lg-4">
                        <h5 class="title text-primary bloc-title-50">Installation professionnelle</h5>
                        <p class="text-muted text-justify">Une fois que les systèmes de climatisation appropriés ont été sélectionnés, notre équipe qualifiée procède à une installation professionnelle et conforme aux normes de l'industrie. Nous veillons à ce que toutes les unités intérieures et extérieures soient correctement positionnées, que les connexions électriques et de plomberie soient sécurisées et que le système soit testé pour garantir un fonctionnement optimal.
                        </p>    
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<!-- End -->
