import { Component } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent {
  navClass = "nav-light";  
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 1,
    decimalPlaces: 0,
  };

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
