<!-- TAGLINE START-->
@if(sliderTopbar == true){
<div class="tagline bg-light">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-slider">
          <ul class="list-inline mb-0 move-text">
            <li class="list-inline-item px-2 mb-0"><b>EOG</b> $ 55.88 <span class="text-success">+$ 4.62 ( +9.01%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>CHKP</b> $ 120.03 <span class="text-danger">-$ 14.07 ( -10.49%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>FANG</b> $ 63.58 <span class="text-success">+$ 5.17 ( +8.84%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>M</b> $ 14.75 <span class="text-success">+$ 1.05 ( +7.66% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>PKI</b> $ 139.72 <span class="text-danger">-$ 11.41 ( -7.55%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ABMD</b> $ 326.23 <span class="text-danger">-$ 21.61 ( -6.21%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN</b> $ 37,471.47 <span class="text-danger">+$ 492.60 (
                +1.33% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>XRP</b> <span> $ 0.39</span><span class="text-muted"> UNCH</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>LITECOIN</b> <span> $ 148.67</span><span class="text-danger">-$
                5.58 ( -3.62% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN CASH</b> <span> $ 427.37</span><span
                class="text-danger">-$ 15.98 ( -3.60% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ETHEREUM</b> $ 1,647.87 <span class="text-danger">+$ 14.51 (
                +0.89% )</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
}
<!-- TAGLINE END-->

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      @if(navClass !== 'nav-light'){
      <a class="logo" routerLink="/">
        <img src="assets/images/logo-dark.png" class="l-dark logo-light-mode" height="75" alt="">
        <img src="assets/images/logo-white.png" class="logo-dark-mode" height="75" alt="">
      </a>
    }
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

      
      @if(navClass === 'nav-light'){
      <a class="logo" routerLink="/">
        <img src="assets/images/logo-dark.png" class="l-dark" height="75" alt="">
        <img src="assets/images/logo-white.png" class="l-light" height="75" alt="">
      </a>
    }
    </div>


    @if(Menuoption == 'center'){
    <div id="navigation">
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Accueil</a></li>
        <li><a routerLink="/services" class="nav-link-ref">Services</a></li>
        <li><a routerLink="/about-us" class="nav-link-ref">Qui sommes nous ?</a></li>
        <li><a routerLink="javascript:void(0)" class="nav-link-ref" (click)="openright(content)">Nous contacter</a></li>
      </ul>
    </div>
    }

  </div>
</header>
<!-- Navbar End -->

<!-- Offcanvas Start -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-header border-bottom">
    <h5 id="offcanvasRightLabel" class="mb-0">
      <img src="assets/images/logo-dark.png" height="75" class="light-version" alt="">
      <img src="assets/images/logo-white.png" height="75" class="dark-version" alt="">
    </h5>
    <button type="button" class="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas"
      aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="uil uil-times fs-4"></i></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
      <div class="col-12">
        <img src="assets/images/contact.png" class=" d-block mx-auto" style="max-width: 180px;" alt="">
        <div class="alert alert-dismissible fade my-2" [ngClass]="alertClass" role="alert">
          <strong>{{alertMessageStatus}}</strong> {{alertMessage}}
          <button type="button" class="btn-close" data-bs-dismiss="alert"
              aria-label="Close"></button>
        </div>
        <div class="card border-0" style="z-index: 1">
          <div class="card-body p-0">
            <form name="myForm" id="myForm" [formGroup]="contactForm" (ngSubmit)="sendEmail()">
              <p id="error-msg" class="mb-0"></p>
              <div id="simple-msg"></div>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Nom <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input name="name" id="name" type="text" formControlName="name" class="form-control ps-5" placeholder="Nom :">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input name="email" id="email" type="email" formControlName="email" class="form-control ps-5" placeholder="Email :">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Téléphone</label>
                    <div class="form-icon position-relative">
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="phone" id="phone" formControlName="phone" class="form-control ps-5" placeholder="téléphone :">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Message <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                      <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                      <textarea name="message" id="message" formControlName="message" rows="4" class="form-control ps-5"
                        placeholder="Message :"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-grid">
                    <button id="submit" type="submit" [disabled]="!contactForm.valid" class="btn btn-primary">Envoyer</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
<!-- Offcanvas End -->