<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Mentions légales </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/">CLIMAPLOM</a></li>
          <li class="breadcrumb-item active" aria-current="page">Mentions légales</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h5 class="card-title">MENTIONS LÉGALES</h5>
            <p class="text-muted">Raison sociale: CLIMAPLOM</p>
            <p class="text-muted">SAS immatriculée au R.C.S. Créteil sous le numéro 953 673 589</p>
            <p class="text-muted">Siège social : 30 Rue Henri Barbusse 94800, Villejuif</p>
            <p class="text-muted">Numéro de téléphone : +33 (0)9 81 18 91 38</p>
            <p class="text-muted">Conception et réalisation du site internet : CLIMAPLOM</p>

            <h5 class="card-title">DROITS DE PROPRIETE ET DROITS D’AUTEUR</h5>
            <p class="text-muted">Le Code de la propriété intellectuelle n’autorisant, aux termes de l’article L. 122-5, (2° et 3°a), d’une part, 
              que les “copies ou reproductions strictement réservées à l’usage privé du copiste et non destinées à une utilisation collective” et, 
              d’autre part, que les analyses et les courtes citations dans un but d’exemple et d’illustration, ” toute représentation ou reproduction 
              intégrale ou partielle faite sans le consentement de l’auteur ou de ses ayants droit ou ayants cause est illicite” (article L. 122-4). 
              Cette représentation ou reproduction, par quelques procédés que ce soit, constituerait donc une contrefaçon sanctionnée par les articles 
              L. 335-2 et suivants du Code de la propriété intellectuelle.</p>
            <p class="text-muted">Tous les droits de propriété et droits d’auteurs relatifs au site concernent de manière non limitative, 
              toute image, photographie, animation, musique, tout élément vidéo ou sonore, toute mini-application et tout textes intégrés au site. 
              Tout contrevenant sera soumis à des poursuites judiciaires, conformément aux droits internationaux de la propriété intellectuelle.</p>
            <p class="text-muted">Tous les éléments sus-cités utilisés à la conception de ce site internet sont libres de droits sous licence du domaine public.</p>
            

            <h5 class="card-title">POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES</h5>
            <p class="text-muted">Les informations recueillies lors d’une inscription à une information ou tout autre formulaire de contact font l’objet d’un traitement informatique destinées à qualifier nos bases prospects/clients et à exercer des opérations de prospections commerciales.</p>
            <p class="text-muted">Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en nous adressant un mail via le formulaire de contact.</p>
            <p class="text-muted">Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.</p>
            <p class="text-muted">Pour plus d’informations concernant le traitement de vos données personnelles, consultez notre page Politique de Confidentialité.</p>
            <h5 class="card-title">POLITIQUE D'UTILISATION DES COOKIES</h5>

            <p class="text-muted">Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.
            </p><p class="text-muted">
              CLIMAPLOM est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à CLIMAPLOM d’améliorer le contenu du Site, de la navigation de l’Utilisateur.
            </p><p class="text-muted">
              Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu ’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu ’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu ’ un Cookie soit susceptible d’être enregistré dans son terminal. CLIMAPLOM informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.
            </p><p class="text-muted">
              Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque CLIMAPLOM ou l’ un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.
            </p><p class="text-muted">
              Le cas échéant, CLIMAPLOM décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par CLIMAPLOM , résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour CLIMAPLOM d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.
            </p><p class="text-muted">
              À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. CLIMAPLOM pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.
            </p><p class="text-muted">
              Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site de CLIMAPLOM ou dans son application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile de CLIMAPLOM , Twitter, Facebook, Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).
            </p><p class="text-muted">
              Ces types de cookies ne sont déposés sur vos terminaux qu ’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de CLIMAPLOM . À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que CLIMAPLOM dépose ce type de cookies.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->
