<section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="title-heading mt-4 text-justify">
                    <h4 class="display-4 mb-4 fw-bold">Votre confort <br> notre expertise</h4>
                    <h3 class="text-primary">La fiabilité et l'efficacité au coeur de notre métier</h3>
                    <p class="para-desc text-muted">Nous proposons des solutions complètes pour répondre à vos besoins de plomberie, chauffage, ventilation et climatisation. Nous sommes là pour vous aider à trouver la solution la plus adaptée à vos besoins spécifiques.</p>
                </div>
            </div>
            <!--end col-->
    
            <div class="col-md-6">
            <img src="assets/images/construction/pl1.png" alt="" class="w-100 ">
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<section class="section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card features-absolute rounded shadow">
                    <div class="row position-relative">
                        <div class="col-lg-12 col-md-12 my-4">
                            <div class="row">

                                <div class="col-md-4 col-12">
                                    <div class="d-flex features feature-primary p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-no-entry align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1 text-justify">
                                            <h4 class="title">Expertise technique avancée</h4>
                                            <p class="text-muted para mb-0">Nos équipes hautement qualifiées, bénéficient d'une expertise technique avancée, assurant des interventions de qualité supérieure.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-md-4 col-12 " style="background: url('assets/images/construction/diagnostic.png'); background-size: contain; background-repeat: no-repeat;background-position: center center;"></div>

                                <div class="col-md-4 col-12">
                                    <div class="d-flex features feature-primary p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-ruler-combined align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1 text-justify">
                                            <h4 class="title">Etude et planification</h4>
                                            <p class="text-muted para mb-0">Nous sommes conscients de l'importance des délais pour nos clients. Notre engagement envers une étude approfondie et une planification précise assure la réalisation des projets dans les délais impartis.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-12 " style="background: url('assets/images/construction/expertise.png'); background-size: contain; background-repeat: no-repeat;background-position: center center;"></div>

                                <div class="col-md-4 col-12">
                                    <div class="d-flex features feature-primary p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-cog align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1 text-justify">
                                            <h4 class="title">Diagnostic Précis, Installations Certifiées</h4>
                                            <p class="text-muted para mb-0">Nos experts réalisent un diagnostic approfondi de vos équipements pour identifier rapidement les problèmes, garantissant ainsi des installations conformes aux normes, alliant qualité et durabilité.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                
                                <div class="col-md-4 col-12 " style="background: url('assets/images/construction/planification.png'); background-size: contain; background-repeat: no-repeat;background-position: center center;">
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                        <!--end col-->

                    </div>
                    <!--end row-->
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 col-12">
                <div class="position-relative">
                    <img src="assets/images/construction/pl2.png" class="rounded img-fluid mx-auto d-block" alt="">
                </div>
            </div>

            <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ms-lg-5 ms-md-4">
                    <div class="section-title text-justify">
                        <span class="badge rounded-pill bg-soft-primary">A propos de nous</span>
                        <h4 class="title mt-3 mb-4">Notre <span
                                class="text-primary">Engagement</span></h4>
                        <p class="text-muted para-desc mx-auto">Bienvenue chez <span
                                class="text-primary fw-bold">CLIMAPLOM</span>, où l'excellence rencontre la passion. Forts d'une expertise reconnue, nous nous dédions à façonner des espaces qui dépassent les attentes de nos clients.</p>
                        <p class="text-muted para-desc mx-auto mb-0">Nous mettons notre savoir-faire au service de votre confort. Nos équipes dévouées, composées de professionnels qualifiés, s'engagent à offrir des solutions sur mesure, assurant ainsi votre satisfaction et votre tranquillité d'esprit.</p>
                        <div class="mt-4">
                            <a href="/about-us" class="btn btn-primary">Lire plus</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Nos équipes d'experts</h4>
                    
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t1.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Managers</a></h5>
                        <!-- <small class="designation text-muted">Associé</small> -->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t3.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Experts CVCP</a></h5>
                        <!-- <small class="designation text-muted">Associé</small> -->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t2.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Chargés d'affaires</a></h5>
                        <!-- <small class="designation text-muted">Associé</small> -->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t4.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Chefs de chantiers</a></h5>
                        <!-- <small class="designation text-muted">Associé</small> -->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->

<!-- Back to top -->