<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Politique de confidentialité </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/">CLIMAPLOM</a></li>
          <li class="breadcrumb-item active" aria-current="page">Politique de confidentialité</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h5 class="card-title">A PROPOS DE NOTRE POLITIQUE DE CONFIDENTIALITE</h5>
            <p class="text-muted">La présente politique de confidentialité a pour but d’informer les utilisateurs du site :</p>
            <p class="text-muted">- Sur la manière dont sont collectées leurs données personnelles. Sont considérées comme des données personnelles, toute information permettant d’identifier un utilisateur. A ce titre, il peut s’agir : de ses noms et prénoms, de son âge, de son adresse postale ou email, de sa localisation ou encore de son adresse IP (liste non-exhaustive)</p>
            <p class="text-muted">- Sur les droits dont ils disposent concernant ces données</p>
            <p class="text-muted">- Sur la personne responsable du traitement des données à caractère personnel collectées et traitées</p>
            <p class="text-muted">- Sur les destinataires de ces données personnelles</p>
            <p class="text-muted">- Sur la politique du site en matière de cookies</p>

            <h5 class="card-title">LA COLLECTE ET LE TRAITEMENT DE VOS DONNÉES PERSONNELLES</h5>
            <p class="text-muted">Conformément à l’article 5 du Règlement européen 2016/679, les données à caractère personnel sont :</p>
            <p class="text-muted">- Traitées de manière licite, loyale et transparente au regard de la personne concernée</p>
            <p class="text-muted">- Collectées pour des finalités déterminées (cf. Article 3.1 des présentes), explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités</p>
            <p class="text-muted">- Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées</p>
            <p class="text-muted">- Exactes et, si nécessaire, tenues à jour. Toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder</p>
            <p class="text-muted">- Conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées</p>
            <p class="text-muted">- Traitées de façon à garantir une sécurité appropriée des données collectées, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées</p>
            
            <p class="text-muted">Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :</p>
            <p class="text-muted">- La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques</p>
            <p class="text-muted">- Le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci</p>
            <p class="text-muted">- Le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis</p>
            <p class="text-muted">- Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique</p>
            <p class="text-muted">- Le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement</p>
            <p class="text-muted">- Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant</p>
            

            <h5 class="card-title">IDENTIFICATION DU RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA PROTECTION DES DONNÉES</h5>
            <p class="text-muted">Les données à caractère personnel sont collectées par la société CLIMAPLOM, dont le numéro d’immatriculation est le 953 673 589 R.C.S. Créteil</p>
            <p class="text-muted">Le délégué à la protection des données (DPO) peut être contacté de la manière suivante :</p>
            <h5 class="text-muted text-center">dpo&#64;climaplom.fr</h5>
            <p class="text-muted">Si vous estimez, après nous avoir contactés, que vos droits “Informatique et Libertés”, ne sont pas respectés, vous pouvez adresser une information à la CNIL.</p>

            <h5 class="card-title">VOS DROITS EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DES DONNÉES</h5>
            <p class="text-muted">Tout utilisateur concerné par le traitement de ses données personnelles peut se prévaloir des droits suivants, en application du règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi 78-17 du 6 janvier 1978) :</p>
            <p class="text-muted">- Droit d’accès, de rectification et droit à l’effacement des données (posés respectivement aux articles 15, 16 et 17 du RGPD)</p>
            <p class="text-muted">- Droit à la portabilité des données (article 20 du RGPD)</p>
            <p class="text-muted">- Droit à la limitation (article 18 du RGPD) et à l’opposition du traitement des données (article 21 du RGPD) </p>
            <p class="text-muted">- Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé</p>
            <p class="text-muted">- Droit de déterminer le sort des données après la mort</p>
            <p class="text-muted">- Droit de saisir l’autorité de contrôle compétente (article 77 du RGPD)</p>
            <p class="text-muted">- Pour exercer vos droits, veuillez adresser votre requête par mail à dpo&#64;climaplom.fr. Afin que le responsable du traitement des données puisse faire droit à sa demande, l’utilisateur peut être tenu de lui communiquer certaines informations telles que : ses noms et prénoms, son adresse e-mail ainsi que son numéro de compte, d’espace personnel ou d’abonné. Consultez le site cnil.fr pour plus d’informations sur vos droits.</p>
            
            <h5 class="card-title">MISE A JOUR DE LA POLITIQUE DE CONFIDENTIALITÉ</h5>
            <p class="text-muted">L’éditeur du site CLIMAPLOM se réserve le droit de pouvoir modifier la présente Politique à tout moment afin d’assurer aux utilisateurs du site sa conformité avec le droit en vigueur. L’utilisateur est invité à prendre connaissance de cette Politique à chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de l’en prévenir formellement. Date de mise à jour de la présente politique de confidentialité</p>
            </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->
