import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private header = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private apiUrl = 'api.climaplom.fr';
  constructor(private http: HttpClient) { }

  sendMail(contact: any): Observable<any> {
    return this.http.post(`https://api.climaplom.fr/v1/sendmail`, contact, {
      headers: this.header,
    });
  }
}
