<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/company/aboutus.jpg');" id="home">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading title-heading">
          <h2 class="text-white title-dark"> A Propos de Nous </h2>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/">Climaplom</a></li>
          <li class="breadcrumb-item active" aria-current="page">Qui sommes nous ?</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6">
        <img src="assets/images/company/about-us-3.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="section-title text-justify">
            <h4 class="title mb-4">Qui sommes nous</h4>
            <p class="text-muted">
              <span class="text-primary fw-bold">CLIMAPLOM, fondée sur des valeurs d'intégrité, d'excellence et de service à la clientèle, nous sommes bien plus qu'une entreprise de plomberie et de chauffage. Nous sommes une équipe dévouée de professionnels passionnés par notre métier, engagés à fournir des services de qualité supérieure et à créer des solutions innovantes pour répondre aux besoins de nos clients.
              </span>
              </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Start -->
<section class="bg-half-170 d-table w-100 bg-light py-5" id="home">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-lg-1">
              <div class="section-title text-center">
                  <img src="assets/images/logo.png" class="img-fluid avatar"
                      alt="">
              </div>
          </div><!--end col-->
          <h4 class="title mt-4 mb-0 text-primary text-center ">CLIMAPLOM</h4>
          <div class="col-lg-12 mt-4 pt-2 text-justify">
              <p class="text-muted">Notre équipe est composée de professionnels qualifiés et expérimentés, dotés des compétences nécessaires pour relever les défis les plus complexes. Nous sommes fiers de notre engagement envers la formation continue et le développement professionnel, ce qui nous permet de rester à la pointe des dernières technologies et des meilleures pratiques de l'industrie.</p>
              <p class="text-muted">Chez CLIMAPLOM, la satisfaction du client est notre priorité absolue. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins et leurs objectifs, et nous nous efforçons de fournir des solutions qui dépassent leurs attentes à chaque étape du processus. Notre engagement envers un service exceptionnel se reflète dans chaque interaction que nous avons avec nos clients, depuis la première consultation jusqu'à la réalisation du projet et au-delà.</p>
              <p class="text-muted">Que vous ayez besoin d'une réparation de plomberie d'urgence, d'une installation de chauffage complexe ou d'une solution de climatisation sur mesure, vous pouvez compter sur CLIMAPLOM pour fournir un service de qualité, fiable et professionnel à chaque fois.</p>
              <p class="text-muted">Nous vous remercions de votre confiance et nous nous réjouissons de travailler avec vous pour répondre à tous vos besoins en matière de plomberie, de chauffage, de ventilation et de climatisation.</p>
            </div><!--end col-->
      </div><!--end row-->
  </div><!--end container-->
</section><!--end section-->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Work Process</h6>
          <h4 class="title mb-4">Comment on fonctionne ?</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-3 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body text-justify">
            <h5 class="text-dark">Consultation et évaluation des besoins</h5>
            <p class="text-muted mb-0">Une fois le contact établi, nous organisons une consultation avec le client pour discuter de ses besoins spécifiques. Cette étape peut inclure une visite sur site pour inspecter les installations existantes.</p>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body text-justify">
            <h5 class="text-dark">Proposition et devis</h5>
            <p class="text-muted mb-0">Sur la base de l'évaluation des besoins, nous préparons une proposition détaillée et un devis pour les travaux à effectuer. Cette proposition comprend généralement une description des services proposés, les coûts associés, les délais d'exécution et toute autre information pertinente.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>
          <div class="card-body text-justify">
            <h5 class="text-dark">Planification</h5>
            <p class="text-muted mb-0">Une fois que la proposition est acceptée, nous planifions les travaux en fonction des disponibilités du client et de notre équipe. nous établissons un calendrier détaillé pour chaque étape du projet, en tenant compte des contraintes de temps et des exigences du client.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 mt-md-5 pt-md-5 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body text-justify">
            <h5 class="text-dark">Exécution des travaux</h5>
            <p class="text-muted mb-0">L'étape suivante consiste à exécuter les travaux conformément à la proposition acceptée et au calendrier convenu. Notre équipe se charge de toutes les tâches nécessaires, en veillant à respecter les normes de qualité et de sécurité les plus élevées.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
      
    
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->


<!-- End -->
